// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from '@remedeelabs/surveys-common';

export const environment: Environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCVBN2CGg01K2yxaLVVGyOTSV6T0grzVmM',
    authDomain: 'remedee-medical-staging.firebaseapp.com',
    projectId: 'remedee-medical-staging',
    storageBucket: 'remedee-medical-staging.appspot.com',
    messagingSenderId: '64840593322',
    appId: '1:64840593322:web:87844498fc8af7143085d3',
    measurementId: 'G-YNHETK9PEF',
  },
  sentry: {
    dsn: 'https://1b5985bd8644352102bc38ed3c39ac5d@o4504690115870720.ingest.sentry.io/4505986355232768',
  },
  functionsBaseUrl:
    'https://europe-west1-remedee-medical-staging.cloudfunctions.net',
  version: '1.0.0-RC1',
  env: 'STAGING',
  isDeveloperMode: false,
};
